<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group label="التاريخ" label-for="blog-edit-title" class="mb-2">
            <flat-pickr v-model="reportDate" class="form-control" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="أعضاء اللجنة" label-for="blog-edit-category" class="mb-2">
            <b-form-input
              id="blog-edit-category"
              v-model="reportcommittee"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="لتقييم" label-for="blog-edit-category" class="mb-2">
            <v-select
              id="blog-edit-category"
              v-model="rating"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="ratingoption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="المحتوى" label-for="blog-content" class="mb-2">
            <quill-editor id="blog-content" v-model="reportText" :options="snowOption" />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="Save()"
          >
            حفظ التقرير
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
export default {
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      refPreviewEl.value.src = base64;
    });

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },

  components: {
    flatPickr,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  data() {
    return {
      reportDate: "",
      reportText: "",
      reportcommittee: "",
      rating: "",
      ratingoption: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      employyList: [],
      statusOption: ["Published", "Pending", "Draft"],
      snowOption: {
        theme: "snow",
      },
    };
  },

  directives: {
    Ripple,
  },

  created() {
    this.$http.get("/api/v1/information_manager/users").then((res) => {
      //console.log(res);
    });
  },

  methods: {
    Save() {
      // return new Promise((resolve, reject) => {
      //   this.$refs.simple
      //     .validate()
      //     .then((success) => {
      //       if (success) {
      let data = {
        content: this.reportText,
        date: this.reportDate,
        person_service_id: 1,
        committee: this.reportcommittee,
        rating: this.rating,
      };
      //console.log(data);
      // let url = `api/v1/person_service_refferals`;

      // //console.log(url);
      // this.$http.post(url, data).then((res) => {
      //   //console.log(res);
      //   this.transferDone = true;
      //   this.$swal({
      //     title: "تم التحويل للحالة بنجاح",
      //     icon: "success",
      //     confirmButtonText: "موافق",
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //     buttonsStyling: false,
      //   });
      // });
    },

    // }}})}
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
